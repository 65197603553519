import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Test Week`}</p>
    <p>{`Bench Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Weighted Strict Pullup 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`800M Run`}</p>
    <p>{`21-Bench Press (Men-bodyweight/Women-60% bodyweight)`}</p>
    <p>{`21-CTB Pullups`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-Bench Press`}</p>
    <p>{`15-CTB Pullups`}</p>
    <p>{`200M Run`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-CTB Pullups`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 12/19/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our monthly free class at 9:00 and 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts on 9/23.  For more info email Eric
at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      